import React, { useContext, useEffect } from "react";
import image from "../../assets/after_login_bg.jpg";
import "../../home.css";
import Content from "../../common/content/Content";
import Layout from "../../common/Layout/Layout";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Redirect } from "react-router-dom";

const CarDashboard = () => {
  const { MiddlewareLogin, setOrderIdArr, setInvoiceIdArr } =
    useContext(AppContext);
  MiddlewareLogin();

  useEffect(() => {
    localStorage.removeItem("selectedSlots");
    localStorage.removeItem("orderId");
    setOrderIdArr([]);
    setInvoiceIdArr([]);
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("carSelect");
  }, []);

  if (
    localStorage.getItem("token") === undefined ||
    !localStorage.getItem("token")
  ) {
    return <Redirect to="/" />;
  }
  return (
    <Layout>
      <div style={{ height: "79.1vh" }}>
        <div className="text-center text-white">
          <section className="container">
            <Content />
            <div className="row p-3">
              <div className="col-md-8 mt-4 pt-5">
                <img src={image} className="img-fluid" />{" "}
              </div>
              <div className="col-md-4 mt-4 pt-5">
                <div className="mb-5 choose-number">
                  <div className="mb-4 text-center">
                    <span className="text-center forgot_password fs-5 p-2">
                      Do you like to book an Inspection On Site or Off Site?
                    </span>

                    <div style={{ marginTop: "10px" }}>
                      <Link to="/Select" className={`is_active`} role="button">
                        On-Site
                      </Link>
                      <Link
                        to={{
                          pathname:
                            "https://safebuy.simplybook.me/v2/#book/category/1/service/14/count/1/provider/any/",
                        }}
                        target="_blank"
                        className={`is_active`}
                        role="button"
                        style={{ marginLeft: "2px" }}
                      >
                        Off-Site
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="text-start dashboard_helpText">
                  <h6>Note:</h6>
                  <p>On Site: $160 Incld GST</p>
                  <p>
                    Off Site: $185 Incld GST upto 50 Kms from Melbourne CBD
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default CarDashboard;
